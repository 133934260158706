import React from 'preact/compat'
// import ReactGA from 'react-ga4'

interface GaWidget {
    isMentor: boolean | undefined
    isFacilitador: boolean
    idTrakingHelpRequest?: string
}

export const setGAWidgetRequestHelp = ({
    isMentor,
    isFacilitador,
    idTrakingHelpRequest,
}: GaWidget) => {
    if (isMentor) {
        // ReactGA.event({
        //     category: 'Help Request',
        //     action: 'Help Requested by Mentor',
        //     label: idTrakingHelpRequest,
        // })
    }

    if (isFacilitador) {
        // ReactGA.event({
        //     category: 'Help Request',
        //     action: 'Help Requested by Facilitator',
        //     label: idTrakingHelpRequest,
        // })
    }

    if (!isMentor && !isFacilitador) {
        // ReactGA.event({
        //     category: 'Help Request',
        //     action: 'Help Requested',
        //     label: idTrakingHelpRequest,
        // })
    }
}

export const setGAWidgetCancelHelp = (idTrakingHelpRequest: string = '') => {
    // ReactGA.event({
    //     category: 'Help Request',
    //     action: 'Help CancelLed',
    //     label: idTrakingHelpRequest,
    // })
}

export const setGAWidgetReturnMentor = (idTrakingHelpRequest: string = '') => {
    // ReactGA.event({
    //     category: 'Help Request',
    //     action: 'Mentor left',
    //     label: idTrakingHelpRequest,
    // })
}

export const setGAWidgetMentorHelpRequest = ({
    idTrakingHelpRequest = '',
    action = '' 
}: {idTrakingHelpRequest?: string, action: '' | 'accepted' | 'rejected' | 'displayed'}) => {

    const getActionText = () => {
        switch (action) {
            case 'accepted':
                return 'Help Request Accepted'
            case 'rejected':
                return 'Help Request Rejected'
            case 'rejected':
                return 'Help Request Displayed'
            default:
                return 'Help Request status no supported'
        }
    }

    // ReactGA.event({
    //     category: 'Help Request',
    //     action: getActionText(),
    //     label: idTrakingHelpRequest,
    // })
}

//Section Feedback
export const setGAWidgetFeedbackSelectorHandUp = () => {
    // ReactGA.event({
    //     category: 'Feedback',
    //     action: 'PositiveFeedbackSent',
    // })
}

export const setGAWidgetFeedbackSelectorHandDown = () => {
    // ReactGA.event({
    //     category: 'Feedback',
    //     action: 'NegativeFeedbackSent',
    // })
}

export const setGAWidgetFeedbackSentComment = () => {
    // ReactGA.event({
    //     category: 'Feedback',
    //     action: 'FeedbackCommentSent',
    // })
}

export const setGAWidgetFeedbackNotSentComment = () => {
    // ReactGA.event({
    //     category: 'Feedback',
    //     action: 'FeedbackCommentSkipped',
    // })
}

//profile
export const setGAWidgetNameProfileView = () => {
    // ReactGA.event({
    //     category: 'Profile',
    //     action: 'NameProfileViewed',
    // })
}

export const setGAErrorHasOccurred = (action: string, error: any) => {
    // ReactGA.event({
    //     category: 'Error',
    //     action: action,
    //     label: error,
    // })
}

export const setGAWidgetRotationLocked = () => {
    // ReactGA.event({
    //     category: 'LockRotation',
    //     action: 'getinto',
    // })
}

export const setGAStudentRecognition = (
    mandatoryVote: boolean,
    mandatoryMentorVote: boolean,
    type: 'give' | 'remove' | 'omit'
) => {
    if(mandatoryMentorVote && mandatoryVote){
        switch (type) {
            case 'give':
                // ReactGA.event({
                //     category: 'Student Recognition',
                //     action: 'RecognizeMentorClicked',
                // })
                break
        }
    } else if (mandatoryVote) {
        switch (type) {
            case 'give':
                // ReactGA.event({
                //     category: 'Student Recognition',
                //     action: 'AcceptVoteClicked',
                // })
                break
            case 'omit':
                // ReactGA.event({
                //     category: 'Student Recognition',
                //     action: 'MandatoryVoteSkipped',
                // })
                break
        }
    } else {
        switch (type) {
            case 'give':
                // ReactGA.event({
                //     category: 'Student Recognition',
                //     action: 'Vote emited',
                // })
                break
            case 'omit':
                // ReactGA.event({
                //     category: 'Student Recognition',
                //     action: 'Vote omited',
                // })
                break
            case 'remove':
                // ReactGA.event({
                //     category: 'Student Recognition',
                //     action: 'Vote removed',
                // })
                break
        }
    }
}

export const setGAWidgetMandatoryVoteMentorClicked = () => {
    // ReactGA.event({
    //     category: 'Student Recognition',
    //     action: 'RecognizeMentorClicked',
    // })
}

export const setGAWidgetMandatoryVoteMentorSkipped = () => {
    // ReactGA.event({
    //     category: 'Student Recognition',
    //     action: 'RecognizeMentorSkipped',
    // })
}

export const setGAWidgetNotification = () => {
    // ReactGA.event({
    //     category: 'Notification',
    //     action: 'NotificationClicked',
    // })
}

export const setGAWidgetStorie = () => {
    // ReactGA.event({
    //     category: 'Notification',
    //     action: 'StoryClicked',
    // })
}

export const setGAWidgetActivateTeam = () => {
    // ReactGA.event({
    //     category: 'ActiveTeam',
    //     action: 'ActiveTeamClicked',
    // })
}

export const setGAWidgetZAppCode = () => {
    // ReactGA.event({
    //     category: 'ZappCode',
    //     action: 'CodeZappClicked',
    // })
}