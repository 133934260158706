import MainLayout from '../../layouts/MainLayout'
import { HCAType, IWidgetInfo } from '../../interfaces/IWidgetInfo'
import { useState, useEffect, useContext } from 'preact/hooks'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MarkAttendanceButton } from '../../components/Buttons/MarkAttendanceButton'
import { AttendanceStatus } from '../../interfaces/AttendanceStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from '../../app'
import { useNotifications } from '../../hooks/useNotifications'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import { Text, useText } from 'preact-i18n'

dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(relativeTime)

import calendar from '../../assets/emptystate/calendar.jpg'
import congrats from '../../assets/emptystate/congrats.jpg'
import hello from '../../assets/emptystate/hello.jpg'
import rocket from '../../assets/emptystate/rocket.jpg'
import calendarAlert from '../../assets/emptystate/calendar-alert.jpg'
import medal from '../../assets/emptystate/medal.jpg'
import { useAttendance } from '../../hooks/useAttendance'
import styled from 'styled-components'
import { colors } from '../../style/variables'

type IProps = {
    widgetInfo: IWidgetInfo
}

type IEmptyState = {
    icon?: any
    title: string | JSX.Element
    msg: string | JSX.Element,
    description?: string | JSX.Element,
    refresh?: boolean
    setPresent?: boolean
    notificateCoach?: boolean
    joinMeeting?: boolean,
    action?: 'notificate_coach' | 'notificated_coach' | 'set_present' | 'join_meeting'
}

const UnassignedView = ({ widgetInfo }: IProps) => {
    
    
    const [emptyState, setEmptyState] = useState<IEmptyState>({ title: '', msg: '' })
    const { setWidgetInfo, updateInfo } = useContext(AppContext)
    const { sendNotification } = useNotifications()
    const [coachNotificated, setCoachNotificated] = useState<boolean>(false)
    const { markAttendance } = useAttendance()

    const { ongoingClass, pleaseWait } = useText({
        ongoingClass: 'attendance.day-late.title',
        pleaseWait: 'attendance.please-wait'
    })


    // Wait for unassigned to be updated and get current empty state case
    useEffect(() => {
        getUnassignedCase()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetInfo])

    const getUnassignedCase = () => {
        
        // eslint-disable-next-line no-debugger
        const now = dayjs().valueOf()
        const start = dayjs(widgetInfo?.team?.nextClass?.start).valueOf()
        const end = dayjs(widgetInfo?.team?.nextClass?.end).valueOf()

        const startFromNow = dayjs(widgetInfo?.team.nextClass?.start).fromNow()
        const startLessTolerance = dayjs(widgetInfo.team?.nextClass.start).subtract(widgetInfo.team?.nextClass?.attendancesRegistrationTime?.attendancesStartTime,'minutes').format('HH:mm')
 
        const presentBeforeStart = dayjs(widgetInfo?.team?.nextClass?.start).subtract(widgetInfo?.team.nextClass?.attendancesRegistrationTime.attendancesStartTime,'minutes').valueOf()
        const startPlusTolerance = dayjs(widgetInfo?.team?.nextClass?.start).add(widgetInfo?.team?.nextClass?.attendancesRegistrationTime.attendancesEndTime,'minutes').valueOf()

        const startHour = dayjs(widgetInfo.team?.nextClass?.start).format('HH:mm')
        const startPlusToleranceFormatted = dayjs(widgetInfo.team?.nextClass.start).add(widgetInfo.team?.nextClass?.attendancesRegistrationTime?.attendancesEndTime, 'minutes').format('HH:mm')
        
        const isEarly = () => now < start
        const isToday = () => dayjs(start).isToday()
        const isInRange = () => dayjs(now).isBetween(presentBeforeStart, startPlusTolerance)
        const isInClass = () => dayjs(now).isBetween(startPlusTolerance, end)

 
        
        if(widgetInfo.team.settings.defaultShouldCheckPresents){

            /**  * Checks if start date for progressive courses has already passed */
            if(widgetInfo.team.settings.algorithmType === HCAType.HCA4) {
                if(widgetInfo.team.startDate?.enabled === false){
                    /**  Si hay una fecha de inicio mayor a hoy  */
                    setEmptyState({
                        icon: calendarAlert,
                        title: <Text id="attendance.start-date.title"></Text>,
                        msg: <Text id="attendance.start-date.desc" fields={{ day: dayjs(widgetInfo.team?.startDate.date).format('DD'), month: dayjs(widgetInfo.team?.startDate.date).format('MMMM') }} ></Text>,
                    })
                    return;
                }
            }

            if(widgetInfo.team.settings.algorithmType === HCAType.HCA4) {
                if(widgetInfo.team.endDate?.enabled === false){
                    /**  Si hay una fecha de inicio mayor a hoy  */
                    setEmptyState({
                        icon: medal,
                        title: <Text id={'completed-course.title'}>Tu curso ha finalizado</Text>,
                        msg: <Text id={'completed-course.description'}>Gracias por formar parte de la comunidad Egg.</Text>,
                        description: <Text id={'completed-course.message'}>Esperamos verte pronto.</Text>
                    })
                    return;
                }
            }


            if (widgetInfo?.team.nextClass ) {
                if (isEarly() && isToday() && !isInRange()) {
                    /**  Si todavía no comienza */
                    setEmptyState({
                        icon: congrats,
                        title: <Text id="attendance.early-day-noRange.title">Llegaste a tiempo</Text>,
                        msg:(<Text id="attendance.early-day-noRange.desc" fields={{ start: startFromNow, startLessTolerance: startLessTolerance, }}/>)
                    })
                } else if (isToday() && isInRange()) {
                    /* If the class is today and its between the start hour and the tolerances */
                    if(widgetInfo.me.own?.attending === AttendanceStatus.UNSET) {
                        /** Si ya comenzó pero está dentro del limite de tolerancia  */
                        setEmptyState({
                            icon: hello,
                            title: (<Text id="attendance.early-day-range.title"> ¡Hola! Gracias por llegar a tiempo. </Text> ),
                            msg: (<Text id="attendance.early-day-range.desc" fields={{ start: startHour, startPlusTolerance: startPlusToleranceFormatted }} /> ),
                            action: 'set_present',
                        })
                    }
                } else if (isToday() && isInClass()) {
                    /** Si ya comenzó pero todavía no termina  */
                    if(widgetInfo.team.settings.automaticAssignAbsents){
                        setEmptyState({
                            icon: rocket,
                            title: <Text id="attendance.day-late.title">La clase ya comenzó</Text>,
                            msg: (<Text id="attendance.day-late.desc"> Aún así puedes ingresar, ver tu equipo del día y participar del encuentro. </Text>),
                            action:'join_meeting',
                        })
                    } else {
                        setEmptyState({
                            icon: rocket,
                            title: <Text id="attendance.day-late.title">La clase ya comenzó</Text>,
                            msg: (<Text id="attendance.day-late.desc"> Aún así puedes ingresar, ver tu equipo del día y participar del encuentro. </Text>),
                            action:'notificate_coach'
                        })
                    }
                } else {
                    /**  Si es otro día  */
                    setEmptyState({
                        icon: calendar,
                        title: ( <Text id="attendance.another-day.title">Tu encuentro está programado</Text> ),
                        msg: ( <Text id="attendance.another-day.desc" fields={{ start: dayjs(widgetInfo.team?.nextClass?.start).format( 'dddd HH:mm') }} /> ),
                    })
                }
            }else {
                /**  Si NO hay próxima clase configurada  */
                setEmptyState({
                    icon: calendar,
                    title: <Text id="attendance.no-day-set.title">Aquí encontrarás a tu equipo</Text>,
                    msg: <Text id="attendance.no-day-set.desc"></Text>,
                })
                return;
            }
        } else {
            setEmptyState({
                icon: calendar,
                title: <Text id="attendance.no-day-set.title">Aquí encontrarás a tu equipo</Text>,
                msg: <Text id="attendance.no-day-set.desc"></Text>,
            })
            return;
        }

    }

    const notificateCoach = () => {
        sendNotification(
            'Nueva solicitud de ingreso',
            `El usuario ${widgetInfo.me.name} ${widgetInfo.me.lastname}, del equipo ${widgetInfo.team.course.name} en ${widgetInfo.team.hq.name} solicita ingresar al evento.`
        )
        .then(() => {
            markAttendance().then(() => {
                setCoachNotificated(true)
                setEmptyState({
                    icon: rocket,
                    title: ongoingClass,
                    msg: pleaseWait,
                    action: 'notificate_coach'
                })
            })
        })
    }

    const getAttendanceText = (value: AttendanceStatus) => {
        switch (value) {
            case AttendanceStatus.UNSET:
                return <Text id="attendance.here">Dar presente</Text>
            case AttendanceStatus.PRESENT:
                return <Text id="attendance.in-time">Presente</Text>
            case AttendanceStatus.DELAY:
                return <Text id="attendance.delay">Tardanza</Text>
            case AttendanceStatus.ABSENT:
                return <Text id="attendance.absent">Ausente</Text>
        }
    }

    const getActionBtn = (action: IEmptyState['action']) =>{
        switch(action){
            case 'notificate_coach': 
                return(
                    <WhiteButton
                        disabled={coachNotificated}
                        className="btn-yellow"
                        style={{ margin: '0 auto' }}
                        onClick={() => notificateCoach()}
                    >
                        <FontAwesomeIcon className="icon" icon={['fal', 'hand-paper']} />
                        {!coachNotificated ? <Text id="attendance.notificate-coach">Dar aviso</Text> : <Text id="attendance.coach-notificated">Coach notificado</Text>}
                    </WhiteButton>
                )
            case 'set_present':
                return(
                    <MarkAttendanceButton
                        attendance={widgetInfo?.me?.own?.attending} // attendance
                        onClick={markAttendance}
                        title="Marcar presente"
                        style={{ margin: '10px auto' }}
                        disabled={widgetInfo?.me?.own?.attending !== AttendanceStatus.UNSET}
                    >
                        <FontAwesomeIcon className="icon" icon={['fal', 'hand-paper']} />
                        {widgetInfo?.me.own && (
                            <span>{getAttendanceText(widgetInfo.me.own.attending)}</span>
                        )}
                    </MarkAttendanceButton>
                )
            case 'join_meeting':
                return(
                    <WhiteButton
                        disabled={coachNotificated}
                        className="btn-yellow"
                        style={{ margin: '0 auto' }}
                        onClick={() => markAttendance()}
                    >
                        <FontAwesomeIcon className="icon" icon={['fal', 'hand-paper']} />
                        <Text id="attendance.join-button">Ingresar</Text>
                    </WhiteButton>
                )
        }
    } 

    

    return (
        <MainLayout centered={true}>
            <div style={{ textAlign: 'center', padding: '0 40px' }}>
                { emptyState?.icon && (<img style={{ width: '80px' }} src={emptyState.icon} alt={'An icon of a rocket'}/> )}
                <TitleStyled>{emptyState.title}</TitleStyled>
                <DescriptionStyled> { emptyState.msg } </DescriptionStyled>
                { emptyState?.action && getActionBtn(emptyState.action) }
                { emptyState?.description && <DescriptionStyled style={{ fontWeight: 600 }}>{ emptyState.description }</DescriptionStyled> }
            </div>
        </MainLayout>
    )
}

export default UnassignedView

const TitleStyled = styled.h2`
    font-size: 1.125rem;
    color: ${ colors.primary400 };
    margin-bottom: 14px;
`


const DescriptionStyled = styled.p`
    font-size: 0.875rem;
    color: ${ colors.primary400 };
    line-height: 22px;
`
