import MainHeading from '../../components/MainHeading'
import ListTable from '../../components/Table/ListTable'
import MainLayout from '../../layouts/MainLayout'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { AppContext } from '../../app'
import styled from 'styled-components'
import ActionsBlock from '../../components/Actions/ActionsBlock'
import { Text } from 'preact-i18n'
import HelpRequestModal from '../../components/Actions/HelpRequestModal'
import HelpRequestNotification from '../../components/Actions/HelpRequestNotification'
import WaitingArea from '../../components/WaitingArea/WaitingArea'
import { UIContext } from '../../context/UIContext'
import { ZoomGetCodeModal } from '../../components/Actions/ZoomGetCodeModal'
import { ActivateTeamModal } from '../../components/Actions/ActivateTeamModal'
import { FeedbackView } from '../FeedbackView/FeedbackView'
import { useNavigation } from '../../hooks/useNavigation'
import { Stories } from '../../components/Storie/Stories'
import { isWithinFiveMinutes } from '../../helpers/Tools'
import { MoveToTeam } from '../../components/MoveToTeam/MoveToTeam'

const TableView = () => {
    const [ready, setReady] = useState<boolean>(false)
    const { widgetInfo, widgetReady, mentorNoticeSeen } = useContext(AppContext)

    const { showFeedback, showRecognizeMentor, setHasNotifications, showStories, setHasStories, setRequestModal, moveTo } = useContext(UIContext)
    const [closeNotification, setCloseNotification] = useState(true)
    const refHeaderContainer = useRef<HTMLDivElement>(null)
    const { navigation } = useNavigation()
    const { mainTimerStart } = useContext(UIContext)

    const cancelHelpRequest = () => {
        setRequestModal('canceled')
    }

    useEffect(() => {
        setRequestModal(undefined)
    }, [widgetInfo?.table])

    // If widget info is ready, set ready this page
    useEffect(() => {
        if (widgetReady) {
            // setShowFeedback(true)
            setHasNotifications(!!widgetInfo?.me.unreadNotifications)

            if (widgetInfo?.me.unreadNotifications) {
                setHasStories(!!widgetInfo?.me.unreadNotifications)
            }

            setReady(true)
            if (widgetInfo?.table) {
                // if (widgetInfo?.me?.tutorialWidgetCompleted === false) {
                //     navigation('/tutorial/student')
                // }
                if (widgetInfo?.table.helpRequest) {
                    setCloseNotification(false)
                }
            }
        }
    }, [widgetReady])

    useEffect(() => {
        if (widgetInfo?.table?.helpRequest?.subStatus && widgetInfo.me.profile._id === widgetInfo.table.helpRequest.from._profileId) {
            if (isWithinFiveMinutes(widgetInfo?.table?.helpRequest?.updatedAt || '')) {
                mainTimerStart()
            }
        }
    }, [widgetReady])

    // This modal should be visible only to mentors, not expert mentors and only on load and when there's no HR in process.
    const shouldISeeMentorModalOnLoad = () => {
        const me = widgetInfo?.table?.positions.find(item => item._profileId === widgetInfo.me.profile._id)
        if (!widgetInfo?.table?.helpRequest) {
            if (me?.isExpertMentor) {
                return
            }
            if (me?.isMentor) {
                if (!mentorNoticeSeen) {
                    setRequestModal('mentor-role-begins')
                }
            }
        }
    }

    useEffect(() => {
        /* 
        Initial state of modals when table is available.
        */
        shouldISeeMentorModalOnLoad()

        if (widgetInfo?.table?.helpRequest?.status === 'mentor-joined') {
            setRequestModal('mentor-joined')
        }
        if (widgetInfo?.table?.helpRequest?.status === 'mentor-selected') {
            setRequestModal('mentor-selected')
        }
    }, [widgetInfo?.table?.helpRequest])

    useEffect(() => {
        if (showRecognizeMentor) {
            navigation('/mandatory-vote-mentor')
        }
    }, [showRecognizeMentor])

    const returnMentor = () => {
        setRequestModal('mentor-leaving')
    }

    useEffect(() => {
        if (widgetInfo) {
            if (widgetInfo?.table?.helpRequest?.subStatus === 'mentor-on-way') {
                console.log('Should start another timer to call a mentor, again with', widgetInfo.table.helpRequest._id)
            }
        }
    }, [widgetInfo])

    if (!ready) {
        return (
            <MainLayout centered={true}>
                <div style={{ textAlign: 'center', padding: '0 40px' }}>
                    <Text id="control.loading" />
                </div>
            </MainLayout>
        )
    }

    const renderView = () => {
        if (widgetInfo?.table) {
            return (
                <ListTableContainer closeNotification={closeNotification}>
                    <ListTable table={widgetInfo?.table} meeting={widgetInfo?.meeting} returnMentor={returnMentor} />
                </ListTableContainer>
            )
        } else {
            return (
                <WaitingAreaContainer>
                    <WaitingArea />
                </WaitingAreaContainer>
            )
        }
    }

    return (
        <MainLayout started={true}>
            <HeadingContainer ref={refHeaderContainer} helpRequest={widgetInfo?.table?.helpRequest}>
                <MainHeading me={widgetInfo?.me} team={widgetInfo?.team} table={widgetInfo?.table} algorithm={widgetInfo?.algorithm} />
                <ActionsBlock widgetInfo={widgetInfo} helpRequest={widgetInfo?.table?.helpRequest} />
                {widgetInfo?.table?.helpRequest && (
                    <HelpRequestNotification helpRequest={widgetInfo?.table.helpRequest} cancelHelpRequest={cancelHelpRequest} />
                )}
            </HeadingContainer>
            {showFeedback ? <FeedbackView /> : <TableContainer>{renderView()}</TableContainer>}

            { moveTo?.showMoveTo && <MoveToTeam /> }
            {/* <HelpRequestModal /> */}
            <ZoomGetCodeModal />
            <ActivateTeamModal />
            <GradientLinearBottom />
            {showStories && <Stories />}
        </MainLayout>
    )
}

export default TableView

const TableContainer = styled.div`
    padding: 0 20px;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 97%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    flex-direction: column;
`
const ListTableContainer = styled.div`
    flex: 1;
`
const WaitingAreaContainer = styled.div`
    flex: 1;
    min-height: calc(100vh - 220px);
    display: flex;
`

const MentorModuleContainer = styled.div`
    padding: 20px 0 30px 0;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
`
const HeadingContainer = styled.div`
    padding: 0 20px;
    padding-bottom: 0px;
    position: sticky;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 97%, rgba(255, 255, 255, 0) 100%);
    z-index: 10;
    width: 100%;
    @media only screen and (max-height: 250px) {
        position: relative;
    }
`
const GradientLinearTop = styled.div`
    height: 14px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 0) 100%);
`

const GradientLinearBottom = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 1) 100%);
`
function callNewMentor(_id: string) {
    throw new Error('Function not implemented.')
}

