import "preact/devtools";
import { createContext } from 'preact'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import TableView from './routes/TableView/TableView'
import MeetingView from './routes/MeetingView/MeetingView'
import './icons/library'
import { StateUpdater, useEffect, useState } from 'preact/hooks'
import UserView from './routes/UserView/UserView'
import MainLayout from './layouts/MainLayout'
import Axios from 'axios'
import 'react-circular-progressbar/dist/styles.css'
import MandatoryVoteView from './routes/MandatoryVoteView/MandatoryVoteView'
import NotificationsView from './routes/NotificationsView/NotificationsView'
import UnassignedView from './routes/UnassignedView/UnassignedView'
import Tutorial from './routes/Tutorial/Tutorial'
import MandatoryVoteMentorView from "./routes/MandatoryVoteMentorView/MandatoryVoteMentorView";
import ReactGA from 'react-ga4'
import { SocketContextProvider } from './context/SocketContext'
import { HelpRequestStatus, IVoteCandidates, IWidgetInfo, IWidgetResponse, IWidgetTable, MentorResult, TablePos } from './interfaces/IWidgetInfo'
import { AttendanceStatus } from './interfaces/AttendanceStatus'

import './assets/styles/slick.css'
import './assets/styles/slick-theme.css'

// Translations
import { IntlProvider } from 'preact-i18n'
import spanish from './locales/es/translation.json'
import english from './locales/en/translation.json'
import portugues from './locales/pt/translation.json'
import { Text } from 'preact-i18n'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/utc'
import UIContextProvider from './context/UIContext'
import { WhiteButton } from './components/Buttons/WhiteButton'

import { setGAErrorHasOccurred } from './helpers/GAnalytics'
import jwt_decode from 'jwt-decode'
import { useHelpRequest } from './hooks/useHelpRequest'


dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

require('dayjs/locale/es')
require('dayjs/locale/pt')

export type ContextType = {
    widgetInfo: IWidgetInfo | undefined
    setWidgetInfo?: StateUpdater<IWidgetInfo | undefined>
    fetchInfo: <T>(update?: T) => Promise<void>
    isFetching: boolean
    widgetReady: boolean | undefined
    infoBlocked: boolean
    updateInfo: (update: any) => void
    updateUserOnTable: (user: any) => void
    removeUserOnTable: (_profileId: string, mentorWaiting?: boolean | undefined) => void
    fetchTeam: (_algorithmId: string) => Promise<void>
    clearCache: () => void
    membersToVote: IVoteCandidates | undefined
    setMembersToVote: StateUpdater<IVoteCandidates | undefined>
    algorithmId: string | undefined
    setAlgorithmId: StateUpdater<string | undefined>
    mandatoryVote: boolean | undefined
    setMandatoryVote: StateUpdater<boolean | undefined>
    enableVoteRoute: boolean | undefined
    setEnableVoteRoute: StateUpdater<boolean | undefined>
    mentorToVote: MentorResult | undefined
    setMentorVote: StateUpdater<MentorResult | undefined>
    mentorNoticeSeen: boolean
    setMentorNoticeSeen: StateUpdater<boolean>
    updateUserWhenNotified: () => void
}

const defaultValues = {
    widgetInfo: undefined,
    fetchInfo: () => Promise.reject(),
    isFetching: false,
    widgetReady: undefined,
    infoBlocked: false,
    updateInfo: () => {},
    updateUserOnTable: () => {},
    removeUserOnTable: () => {},
    fetchTeam: () => Promise.reject(),
    clearCache: () => null,
    membersToVote: undefined,
    setMembersToVote: () => undefined,
    algorithmId: undefined,
    setAlgorithmId: () => undefined,
    mandatoryVote: undefined,
    setMandatoryVote: () => undefined,
    enableVoteRoute: true,
    setEnableVoteRoute: () => undefined,
    mentorToVote: undefined,
    setMentorVote: () => undefined,
    mentorNoticeSeen: false,
    setMentorNoticeSeen: () => undefined,
    updateUserWhenNotified: () => undefined,
}

type TokenType = {
    exp: number
    iat: number
    role: string
    _id: string
    _profileId: string
    _teamId: string
}

export type MandatoryVoteStatus = {
    shouldVote: boolean
    table: IVoteCandidates
    _algorithmId: string
}

// Feeds info and functions to app
export const AppContext = createContext<ContextType>(defaultValues)

const App = () => {
    // Local loading
    const [ready, setReady] = useState<boolean | undefined>(false)
    // Used to know if data is fetched
    const [widgetReady, setWidgetReady] = useState<boolean | undefined>(undefined)
    // Main widget info from user/info
    const [widgetInfo, setWidgetInfo] = useState<IWidgetInfo | undefined>(undefined)
    // Shows error
    const [fetchError, setFetchError] = useState<boolean>(false)
    // Bool used to show spinners on app
    const [isFetching, setIsFetching] = useState<boolean>(false)
    // Sync with platform setting
    // const [setMandatoryVote] = useState<boolean>(false)
    // Bool if user is not assigned
    const [isAssigned, setIsAssigned] = useState<boolean | undefined>(undefined)
    // Language definition
    const [definition, setDefinition] = useState(spanish)
    // Block socket if info is running
    const [infoBlocked, setInfoBlocked] = useState(false)
    // Memebers to vote if mandatory vote is true
    const [membersToVote, setMembersToVote] = useState<IVoteCandidates | undefined>(undefined)
    // Members to get algorithmId if can vote
    const [algorithmId, setAlgorithmId] = useState<string | undefined>(undefined)
    const [mandatoryVote, setMandatoryVote] = useState<boolean | undefined>(false)
    const [enableVoteRoute, setEnableVoteRoute] = useState<boolean | undefined>(true)
    const [mentorToVote, setMentorVote] = useState<MentorResult | undefined>(undefined)
    const [mentorNoticeSeen, setMentorNoticeSeen] = useState<boolean>(false)

    const cacheEnabled = process.env.PREACT_APP_CACHE_ENABLED === 'true'

    const getToken = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get('token')
        return token
    }

    // Checks if there is a token
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get('token')
        Axios.defaults.headers.common['Authorization'] = token

        let lang = queryParams.get('lang')

        switch (lang) {
            case 'en-US':
                setDefinition(english)
                dayjs.locale('en')
                break
            case 'pt-BR':
                setDefinition(portugues)
                dayjs.locale('pt')
                break
            default:
                dayjs.locale('es')
                setDefinition(spanish)
        }

        // Case first widget load
        if (token) fetchInfo(undefined, true).then(() => setWidgetReady(true))
        else {
            console.error('No token')
            setReady(true)
            setWidgetReady(false)
        }
    }, [])

    // Async function to check if user should vote,
    // Wait until api responds and setFlag. then, redirect on render.
    const checkMandatoryVote = async () => {
        const { data }: { data: { data: MandatoryVoteStatus } } = await Axios.get(
            `${process.env.PREACT_APP_WIDGET_API}/v1/vote/status/${widgetInfo?.me.profile._id}`
        )
        if (data.data.shouldVote) {
            setAlgorithmId(data.data._algorithmId)
            setMembersToVote(data.data.table)
        }
        setMandatoryVote(data.data.shouldVote)
    }

    useEffect(() => {
        if (widgetInfo?.me.profile._id) {
            checkMandatoryVote()
        }
    }, [widgetReady])

    useEffect(() => {
        // Wait until widget info is full filed and set Mandatory Vote
        // popup according to shouldVote and mandatoryVote properties
        if (widgetInfo) {
            // Even though mentors without a table aren't assigned, flag is set to true to be able to showthem the mentors view. Otherwise, they would drop to the 'UnassignedView'
            if (widgetInfo.team.settings.defaultShouldCheckPresents) {
                if (widgetInfo.me.own?.attending !== AttendanceStatus.UNSET) {
                    setIsAssigned(true)
                } else {
                    setIsAssigned(false)
                }
            } else if (widgetInfo.table) {
                setIsAssigned(true)
            } else {
                setIsAssigned(false)
            }

            ReactGA.set({
                dimension1: 'widget',
                dimension2: widgetInfo.team.hq.name,
                dimension3: widgetInfo.team.course.name,
                dimension4: widgetInfo.team.course.name,
                dimension5: widgetInfo.me.profile._id,
                dimension7: 'student',
                dimension8: 'flat_list_view',
                dimension9: widgetInfo.team.settings.algorithmType,
                dimension10: dayjs(widgetInfo.team.startDate?.date).utc(true).format('DD/MM/YYYY'),
            })
        }
    }, [widgetInfo])

    useEffect(() => {
        // Log React.GA
        ReactGA.initialize('G-QZ3V2R6K47');

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, [])

   

    const fetchInfo = async (update?: any, preferCache?: boolean) => {
        if (!widgetInfo) {
            setReady(false)
        }

        //  Fetch info from session storage if exists. ONLY WORKS IF ENVIROMENT IS PROD, IS ENABLE ON THE COURSE AND ON RELOAD.
        try {
            if (window.sessionStorage.getItem('widgetInfo')) {
                let sessionInfo = window.sessionStorage.getItem('widgetInfo')
                if (sessionInfo) {
                    const parsedStorage: IWidgetInfo = JSON.parse(sessionInfo)
                    if (preferCache && cacheEnabled && parsedStorage.team.settings.widgetFrontCache) {
                        const expire = Number(window.sessionStorage.getItem('cache_expire'))
                        const now = Number(dayjs().unix())
                        if (expire > now) {
                            const decodedToken: TokenType = jwt_decode(getToken() || '')
                            if (sessionInfo && decodedToken._profileId === parsedStorage?.me.profile._id) {
                                setWidgetInfo(parsedStorage)
                                setReady(true)
                                setIsFetching(false)
                                return
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log('Cant get sessionStorage, access on a normal tab')
            // alert('Por favor ingresa desde una pestaña sin modo incógnito.')
        }

        setIsFetching(true)
        try {
            setInfoBlocked(true)
            const res = await Axios.get<IWidgetResponse>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/info`, { timeout: 30000 })
            setFetchError(false)
            if (update) {
                const newInfo = Object.assign(res.data.data, {
                    table: {
                        ...res.data.data.table,
                        ...update,
                    },
                })
                updateInfo({ ...newInfo })
            } else {
                setInfoBlocked(false)
                setFetchError(false)
                setWidgetInfo({ ...res.data.data })
                try {
                    cacheEnabled && window.sessionStorage.setItem('widgetInfo', JSON.stringify(res.data.data))
                    cacheEnabled && window.sessionStorage.setItem('cache_expire', JSON.stringify(dayjs().add(2, 'minute').unix()))
                } catch (error) {
                    console.log('Cant set sessionStorage')
                }
            }
        } catch (error) {
            setFetchError(true)
            setWidgetInfo(undefined)
            setGAErrorHasOccurred('Fetch info failed', error)
        } finally {
            setReady(true)
            setIsFetching(false)
        }
    }

    const fetchTeam = async (_algorithmId: string) => {
        if (!widgetInfo) {
            return
        }
        setIsFetching(true)
        try {
            const res = await Axios.get<{ data: IWidgetInfo['table'] }>(`${process.env.PREACT_APP_WIDGET_API}/v1/algorithm/${_algorithmId}/table`, {
                timeout: 30000,
            })
            updateInfo({
                table: res.data.data,
                algorithm: { _id: _algorithmId, isMentor: undefined },
            })
        } catch (error) {
            console.log('Error fetching team', error)
            fetchInfo()
            setGAErrorHasOccurred('Fetch teams failed', error)
        }
    }

    // Helper update widget info
    const updateInfo = (update: Partial<IWidgetInfo>) => {
        const w = JSON.parse(JSON.stringify(widgetInfo))
        const u = JSON.parse(JSON.stringify(update))
        const clone = { ...w, ...u }
        widgetInfo && setWidgetInfo?.(clone)
        try {
            cacheEnabled && window.sessionStorage.setItem('widgetInfo', JSON.stringify(clone))
        } catch (error) {
            console.log('Cant updateInfo on sessionStorage')
        }
    }

    const updateUserOnTable = (user: Partial<TablePos>) => {
        widgetInfo &&
            setWidgetInfo((prev: any) => {
                if (prev) {
                    return {
                        ...prev,
                        table: {
                            ...prev.table,
                            positions: [user, ...prev.table?.positions],
                        },
                    }
                } else return prev
            })
    }

    const updateUserWhenNotified = () => {
        widgetInfo &&
            setWidgetInfo((prev: any) => {
                return {
                    ...prev,
                    me: {
                        ...prev.me,
                        hasNotifications: true,
                        unreadNotifications: true,
                        allReadStories: false,
                        hasStory: true,
                    },
                }
            })
    }

    const removeUserOnTable = (_profileId: string, mentorWaiting?: boolean) => {
        if (!mentorWaiting) {
            widgetInfo &&
                setWidgetInfo((prev: any) => {
                    if (prev) {
                        return {
                            ...prev,
                            table: {
                                ...prev.table,
                                positions: prev.table?.positions.filter((pos: TablePos) => pos._profileId !== _profileId),
                                helpRequest: !mentorWaiting
                                    ? prev.table.helpRequest
                                    : { ...prev.table.helpRequest, status: HelpRequestStatus.MENTOR_SELECTED },
                            },
                        }
                    } else return prev
                })
        }
    }

    const clearCache = () => {
        if (cacheEnabled) {
            try {
                window.sessionStorage.removeItem('widgetInfo')
                window.sessionStorage.removeItem('cache_expire')
            } catch (error) {
                console.log('Cant clear sessionStorage on incognito')
            }
        }
    }

    const getRoute = () => {
        // Fetching

        if (!ready) {
            return (
                <IntlProvider definition={definition}>
                    <MainLayout centered={true}>
                        <div style={{ textAlign: 'center', padding: '0 40px' }}>
                            <Text id="control.loading" />
                        </div>
                    </MainLayout>
                </IntlProvider>
            )
        }

        if (fetchError || !widgetReady) {
            return (
                <IntlProvider definition={definition}>
                    <MainLayout centered={true}>
                        <div style={{ textAlign: 'center', padding: '0 40px' }}>
                            <Text id="control.not_ready" />
                            <div>
                                <WhiteButton style={{ margin: '10px auto 0 auto' }} onClick={() => fetchInfo()}>
                                    <Text id="control.retry" />
                                </WhiteButton>
                            </div>
                        </div>
                    </MainLayout>
                </IntlProvider>
            )
        }

        // If user is not assigned
        if (!isAssigned && isAssigned !== undefined && widgetInfo) {
            return (
                <IntlProvider definition={definition}>
                    <BrowserRouter>
                        <UnassignedView widgetInfo={widgetInfo} />
                    </BrowserRouter>
                </IntlProvider>
            )
        }

        // If shouldVote === true
        if (mandatoryVote && enableVoteRoute && widgetInfo?.config.mandatoryVote) {
            if (widgetInfo?.table && widgetInfo?.table?.positions?.length > 1) {
                return (
                    <IntlProvider definition={definition}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<MandatoryVoteView />} />
                            </Routes>
                        </BrowserRouter>
                    </IntlProvider>
                )
            }
        }

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<TableView />} />
                    <Route path="/user/:id" element={<UserView />} />
                    <Route path="/meeting" element={<MeetingView />} />
                    <Route path="/notifications" element={<NotificationsView />} />
                    <Route path="/tutorial/:page" element={<Tutorial />} />
                    <Route path="/mandatory-vote" element={<MandatoryVoteView />} />
                    <Route path="/mandatory-vote-mentor" element={<MandatoryVoteMentorView />} />
                </Routes>
            </BrowserRouter>
        )
    }

    return (
        <div id="widget-egg">
            <IntlProvider definition={definition}>
                <AppContext.Provider
                    value={{
                        widgetInfo,
                        setWidgetInfo,
                        fetchInfo,
                        isFetching,
                        widgetReady,
                        infoBlocked,
                        updateInfo,
                        updateUserOnTable,
                        removeUserOnTable,
                        fetchTeam,
                        clearCache,
                        membersToVote,
                        setMembersToVote,
                        algorithmId,
                        setAlgorithmId,
                        mandatoryVote,
                        setMandatoryVote,
                        enableVoteRoute,
                        setEnableVoteRoute,
                        mentorToVote,
                        setMentorVote,
                        mentorNoticeSeen,
                        setMentorNoticeSeen,
                        updateUserWhenNotified,
                    }}
                >
                    <UIContextProvider>
                        <SocketContextProvider>{getRoute()}</SocketContextProvider>
                    </UIContextProvider>
                </AppContext.Provider>
            </IntlProvider>
        </div>
    )
}

export default App

// Interface
type iLanguage = 'en-US' | 'es-AR' | 'pt-BR' | null
