/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { AvatarEggStyled } from './AvatarEggStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { TablePos } from '../../interfaces/IWidgetInfo'
import useUser from '../../hooks/useUser'
import { UIContext } from '../../context/UIContext'
import { AppContext } from '../../app'
import ReactGA from 'react-ga4'

interface IAvatarEggProps {
    src: string
    badge?: any
    title?: string
    facilitator?: boolean
    hasVoted?: boolean
    size?: number
    style?: Record<string, any>
    animate?: boolean
    isMe?: boolean
    handleFileSelect?: (e: any) => void
    bgColor?: any
    hasTooltip?: boolean,
    isEditable?: boolean,
    isMentor?: boolean
    user?: TablePos | undefined
}

const AvatarEgg = (props: IAvatarEggProps) => {
    const { src, title, hasTooltip } = props
    const avatarImgRef = useRef<any>(null)
    const [source, setSource] = useState<string | undefined>(src)
    const fileInput = useRef<HTMLInputElement>(null)
    const [imageIsLoading, setImageIsLoading] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const { updateUser } = useUser(props.user?._userId._id || '')
    const { dispatchNotification } = useContext(UIContext)
    const { widgetInfo } = useContext(AppContext)

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')

    const handleFileSelect = (event: any) => {
        ReactGA.event({
            category: 'Profile',
            action: 'EditPictureClicked',
        })
        const selectedFile = event.target.files?.[0]
        if (!selectedFile) return
        handleUploadPhoto(selectedFile)
    }

    const handleUploadPhoto = async (fileToUpload: Blob) => {
        try {
            setImageIsLoading(true)
            const formData = new FormData()
            formData.append('file', fileToUpload)
            const response = await fetch(`${process.env.PREACT_APP_S3}/${widgetInfo?.me._id}`, {
                method: 'POST',
                body: formData,
                headers:{
                    'Authorization': `${token}`
                }
            })
            const json = await response.json()
            await updateUser({ urlImage: `${process.env.PREACT_APP_IMAGE_S3}/${json.thumbnailKey}` })
            setSource(`${process.env.PREACT_APP_IMAGE_S3}/${json.thumbnailKey}`)
        } catch (error) {
            console.log('Error uploading image', error)
            dispatchNotification({ type: null, payload: {
                title: 'Error',
                subtitle: 'Failed to upload image. Try again later.'
            }})

        } finally {
            setImageIsLoading(false)
        }
    }

    // Update src if src changes
    useEffect(() => {
        setSource(src)
    }, [src])

    return (
        <AvatarEggStyled
            title={title && title}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            isEditabe={props.isMe}
            imageIsLoading={imageIsLoading}
            {...props}
        >
            <input
                style={{ display: 'none' }}
                type="file"
                onChange={handleFileSelect}
                ref={fileInput}
            />
            <div className="main">
                {!source ? (
                    <span className="gravatar">
                        {title
                            ?.split(' ')
                            .map(word => word.slice(0, 1))
                            .join('')
                            .toUpperCase()
                            .substring(0, 2)}
                    </span>
                ) : (
                    <img
                        src={source}
                        alt={title}
                        ref={avatarImgRef}
                        // if fails to load src, set to undefined to show user-icon
                        onError={() => {
                            avatarImgRef.current.src = setSource(undefined)
                        }}
                    />
                )}
            </div>
            {props.facilitator && (
                <div className="badge facilitator">
                    <FontAwesomeIcon className="icon" icon={['fas', 'medal']} />
                </div>
            )}
            {props.isEditable && (
                <div className="badge image-upload">
                    <FontAwesomeIcon className="icon" icon={['fas', 'camera']} />
                </div>
            )}
            {props.isMentor && (
                <div className="badge isMentor">
                    <FontAwesomeIcon className="icon" icon={['fas', 'graduation-cap']} />
                </div>
            )}
            {hasTooltip && showTooltip && <div className="tooltip">{title?.substring(0, 13)}</div>}
            {(props.isEditable || imageIsLoading) && <button onClick={() => fileInput.current?.click()} aria-label="Upload profile image" className="overlay">{imageIsLoading ? <FontAwesomeIcon icon={['fal', 'spinner']} spin/> : <span>Cargar<br/>Imagen</span>}</button>}
        </AvatarEggStyled>
    )
}

export default AvatarEgg
