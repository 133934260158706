export const colors = {
    eggColor: 'rgb(255, 205, 0)',
    darkGreyEgg: '#003750',
    blueEgg: '#00A3FF',
    washedGreyEgg: '#879DA7',
    mediumGreyBg: '#D2D2D2',
    lightGreyBg: '#F8F8F8',
    paragraphColor: '#003750',
    separadorLineas: '#EAEAEA',
    eggPurple: '#BF03E2',
    eggDarkPurple: '#7B0091',
    eggDarkPurpleBorders: '#6E0083',
    purple: '#BF03E2',
    eggPurpleLigth: '#DF42FC',
    eggPink: '#F2D4FF',
    eggPinkDarker: '#F0BFFF',
    borders: '#EDEDED',
    eggFacilitators: '#FF8A00',
    eggGreyNotifications: '#A3A3A3',
    // paleta de colores
    primary: '#003750',
    primary400: '#073045',
    primary700: '#1F394C',
    darkGray: '#A3A3A3',
    gray: '#C7C7C7',
    lightGray: '#EAEAEA',
    perl: '#F2F2F2',
    offWhite: '#F8F8F8',
    white: '#FFFFFF',
    green: '#15C1B2',
    green700: '#15C1B0',
    yellow: '#FFCD00',
    orange: '#FFBA00',
    salmon: '#FF647C',
    lightBlue: '#00A3FF',
    blue: '#2B75FF',
    darkBlue: '#105DF9',
    errors: '#FF647C',
}