import { createContext } from 'preact'
import { StateUpdater, useContext, useEffect, useReducer, useState } from 'preact/hooks'
import { Notifications } from '../routes/NotificationsView/NotificationsView'
import ToastNotification from '../components/ToastNotification'
import { BottomNotification, ContainerCenterNotification } from '../components/BottomNotifications/BottomNotification'
import { useTimer } from 'react-timer-hook'
import dayjs from 'dayjs'
import { AppContext } from '../app'
import { useHelpRequest } from '../hooks/useHelpRequest'
import { HelpModalType } from '../interfaces/IWidgetInfo'

export type ContextType = {
    setToast: StateUpdater<ToastType | undefined>
    requestNotification: RequestNotification
    setRequestNotification: StateUpdater<RequestNotification>
    canSetPresent: boolean | undefined
    setCanSetPresent: StateUpdater<boolean | undefined>
    showZoomCode: boolean | undefined
    setShowZoomCode: StateUpdater<boolean | undefined>
    showDeleteNotifications: boolean | undefined
    setShowDeleteNotifications: StateUpdater<boolean | undefined>
    showFeedback: boolean | undefined
    setShowFeedback: StateUpdater<boolean | undefined>
    showRecognizeMentor: boolean | undefined
    setShowRecognizeMentor: StateUpdater<boolean | undefined>
    timeCurrent: number
    setTimeCurrent: StateUpdater<number>
    notifications: Notifications[]
    setNotifications: StateUpdater<Notifications[]>
    hasNotifications: boolean
    setHasNotifications: StateUpdater<boolean>
    showStories: boolean
    setShowStories: StateUpdater<boolean>
    hasStories: boolean
    setHasStories: StateUpdater<boolean>
    showActivateTeam: boolean
    setShowActivateTeam: StateUpdater<boolean>
    disabledActivateTeam: boolean
    setDisabledActivateTeam: StateUpdater<boolean>
    selectedNotification: JSX.Element | null
    dispatchNotification: Dispatch<Action>
    mainTimerStart: any
    mainTimerRestart: any
    requestModal: HelpModalType
    setRequestModal: StateUpdater<HelpModalType>,
    moveTo: MoveTo | undefined,
    setMoveTo: StateUpdater<MoveTo | undefined>
}

export type MoveTo = {
    showMoveTo: boolean,
    payload?: MoveToPayload,
}
export type MoveToPayload = {
    tableNumber: number,
    meetingSwitchType: string,
    eventName: 'mentor-has-joined' | 'ui-team-has-updated',
    meetingUrl?: string
}

export type BottomNotification = {
    type: 'success' | 'error'
    title: string
    message: string
}

export type ToastType = [type: 'success' | 'error', message: string]

type Dispatch<A> = (value: A) => void

export type Notification = {
    _id: string
    from_Id: string
    to_Id?: string
    toTableNumber?: number
    _user: {
        name: string
        lastname: string
        img_url: string
    }
    status: string
    createdAt: string
}

type Action = {
    type: NotificationType | null
    payload?: payload
}

export type payload = {
    icon?: JSX.Element | null
    title: string
    subtitle?: string
}

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
}

const reducer = (state: any, action: Action) => {
    if (action.type === null && action.payload) {
        action.type = NotificationType.ERROR
    }

    switch (action.type) {
        case NotificationType.SUCCESS:
            return <BottomNotification type={NotificationType.SUCCESS} payload={action.payload} />
        case NotificationType.ERROR:
            return <BottomNotification type={NotificationType.ERROR} payload={action.payload} />
        default:
            return null
    }
}

const defaultValues = {
    setToast: () => undefined,
    requestNotification: undefined,
    setRequestNotification: () => undefined,
    canSetPresent: undefined,
    setCanSetPresent: () => undefined,
    showZoomCode: undefined,
    setShowZoomCode: () => undefined,
    showFeedback: undefined,
    setShowFeedback: () => undefined,
    showRecognizeMentor: undefined,
    setShowRecognizeMentor: () => undefined,
    timeCurrent: 120,
    setTimeCurrent: () => undefined,
    showDeleteNotifications: undefined,
    setShowDeleteNotifications: () => undefined,
    notifications: [],
    setNotifications: () => [],
    hasNotifications: false,
    setHasNotifications: () => undefined,
    showStories: false,
    setShowStories: () => undefined,
    hasStories: false,
    setHasStories: () => undefined,
    showActivateTeam: false,
    setShowActivateTeam: () => false,
    disabledActivateTeam: false,
    setDisabledActivateTeam: () => false,
    selectedNotification: null,
    dispatchNotification: () => {},
    mainTimerStart: () => {},
    mainTimerRestart: () => {},
    requestModal: undefined,
    setRequestModal: () => {},
    moveTo: undefined,
    setMoveTo: () => undefined
}

// Feeds info and functions to app
export const UIContext = createContext<ContextType>(defaultValues)

type RequestNotification =
    | {
          status:
              | 'requested'
              | 'canceled'
              | 'mentor-help'
              | 'mentor-joined'
              | 'mentor-joined-me'
              | 'last-mentor-left'
              | 'ended'
              | 'professor-is-coming'
              | 'mentor-rejected'
              | 'hr-expired'
          payload?: any
      }
    | undefined

const initialState = null

const UIContextProvider = ({ children }: any) => {
    // Local loading
    const [toast, setToast] = useState<ToastType | undefined>(undefined)
    const [requestNotification, setRequestNotification] = useState<RequestNotification>(undefined)
    const [canSetPresent, setCanSetPresent] = useState<boolean | undefined>(undefined)
    const [showZoomCode, setShowZoomCode] = useState<boolean | undefined>(false)
    const [showFeedback, setShowFeedback] = useState<boolean | undefined>(undefined)
    const [showRecognizeMentor, setShowRecognizeMentor] = useState<boolean | undefined>(undefined)
    const [timeCurrent, setTimeCurrent] = useState<number>(120)
    const [showDeleteNotifications, setShowDeleteNotifications] = useState<boolean | undefined>(false)
    const [notifications, setNotifications] = useState<Notifications[]>([])
    const [hasNotifications, setHasNotifications] = useState<boolean>(false)
    const [showStories, setShowStories] = useState<boolean>(false)
    const [hasStories, setHasStories] = useState<boolean>(false)
    const [showActivateTeam, setShowActivateTeam] = useState<boolean>(false)
    const [disabledActivateTeam, setDisabledActivateTeam] = useState<boolean>(false)
    const [selectedNotification, dispatchNotification] = useReducer(reducer, initialState)
    const [requestModal, setRequestModal] = useState<HelpModalType>(undefined)
    const [moveTo, setMoveTo] = useState<MoveTo | undefined>(undefined)
    const { widgetInfo } = useContext(AppContext)
    const { callNewMentor } = useHelpRequest()

    const { seconds, start: mainTimerStart, isRunning, restart: mainTimerRestart } = useTimer({
        autoStart: false,
        expiryTimestamp: dayjs().add(60, 's').toDate(),
        onExpire: () => callNewMentor(widgetInfo?.table?.helpRequest?._id || ''),
    })

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(undefined)
            }, 3000)
        }
    }, [toast])

    useEffect(() => {
        console.log('s', seconds)
        console.log('isRunning', isRunning)
    }, [seconds, isRunning])

    const values = {
        toast,
        setToast,
        requestNotification,
        setRequestNotification,
        canSetPresent,
        setCanSetPresent,
        showZoomCode,
        setShowZoomCode,
        showFeedback,
        setShowFeedback,
        showRecognizeMentor,
        setShowRecognizeMentor,
        timeCurrent,
        setTimeCurrent,
        showDeleteNotifications,
        setShowDeleteNotifications,
        notifications,
        setNotifications,
        hasNotifications,
        setHasNotifications,
        showStories,
        setShowStories,
        hasStories,
        setHasStories,
        showActivateTeam,
        setShowActivateTeam,
        disabledActivateTeam,
        setDisabledActivateTeam,
        selectedNotification,
        dispatchNotification,
        mainTimerStart,
        mainTimerRestart,
        requestModal,
        setRequestModal,
        moveTo,
        setMoveTo
    }

    return (
        <UIContext.Provider value={values}>
            {children}
            {toast && <ToastNotification toast={toast} />}
            {selectedNotification != null && <ContainerCenterNotification>{selectedNotification}</ContainerCenterNotification>}
        </UIContext.Provider>
    )
}

export default UIContextProvider