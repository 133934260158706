import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserRole } from '../../../interfaces/IWidgetInfo'
import { ButtonQuitStyled } from './ButtonQuitStyled'

type Props = {
    role: UserRole | undefined
    returnMentor: () => void,
    disabled?: boolean
}

const ButtonQuit = ({ role, returnMentor, disabled }: Props) => {
    return (
        <ButtonQuitStyled disabled={disabled} role={role} onClick={returnMentor}>
            <FontAwesomeIcon className="icon" icon={['fas', 'arrow-right-from-bracket']} />
            Salir
        </ButtonQuitStyled>
    )
}

export default ButtonQuit
