import { useContext, useEffect, useState } from 'preact/hooks'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import styled from 'styled-components'
import { AppContext } from '../../app'
import MainLayout from '../../layouts/MainLayout'
import { MainTitle, LeadTextBlock } from '../../components/Type/Title'
import { useVoting } from '../../hooks/useVoting'
import ReactGA from 'react-ga4'
import { Text } from 'preact-i18n'
import { TextButton } from '../../components/Buttons/TextButton'
import { TablePos, UserRole } from '../../interfaces/IWidgetInfo'
import { ListItemNew } from '../../components/Table/ListItem/ListItem'

const MandatoryVoteView = () => {
    const { widgetInfo, updateInfo, membersToVote, algorithmId, setEnableVoteRoute } = useContext(AppContext)
    const [memberToVote, setMemberToVote] = useState<string | undefined>(undefined)
    const { voteStudent } = useVoting()

    useEffect(() => {
        ReactGA.gtag({
            page_path: '/mandatory-vote', 
            modal_name: 'mandatory-vote-modal'
        })
    }, [])

    const givePulse = async (memberToVote: string, algorithmId: string) => {
        widgetInfo && updateInfo({ me: { ...widgetInfo?.me, shouldVote: false } })
        voteStudent(memberToVote, algorithmId, true)
        // later of vote or not vote to go a home page
        setEnableVoteRoute(false)
    }

    const setVotingMembers = (_profileId: string) => {
        setMemberToVote(prev => (prev === _profileId ? undefined : _profileId))
    }

    const getUserLabel = (item: TablePos) => {
        if (item.isMentor && !item.isExpertMentor) {
            return 'roles.mentor'
        }
        if (item.isExpertMentor) {
            return 'roles.expertMentor'
        }
        return ''
    }

    return (
        <MainLayout>
            <Resume>
                <MainTitle style={{ marginTop: '10px', textAlign: 'center', fontSize: '17px', lineHeight: '23px', color: '#1F394C' }}>
                    <Text id={'mandatory_vote.title'}>Reconocimiento</Text>
                </MainTitle>
                <LeadTextBlock style={{ margin: '0px 0px 10px 0px', textAlign: 'center', fontSize: '14px', lineHeight: '22px', color: '#073045' }}>
                    <Text id={'mandatory_vote.msg'}>Antes de continuar, reconoce al compañero que más te ayudó en el último día de trabajo</Text>
                </LeadTextBlock>
                <VoteList style={{ height: 'calc(100vh - 200px)', overflowY: 'scroll', marginTop: '5px' }}>
                    {
                        // candidates && candidates.positions
                        membersToVote?.positions.map(dent => {
                            return (
                                <ListItemNew
                                    canRedirect={false}
                                    item={dent}
                                    key={dent._profileId}
                                    role={UserRole.STUDENT}
                                    isMentorTeam={false}
                                    returnMentor={() => null}
                                    pulse={true}
                                    onClick={setVotingMembers}
                                    voted={dent._profileId === memberToVote}
                                    userLabel={getUserLabel(dent)}
                                />
                            )
                        })
                    }
                </VoteList>
                <div
                    style={{
                        width: '100%',
                        position: 'fixed',
                        bottom: 0,
                        padding: '10px 20px',
                        background: 'white',
                    }}
                >
                    <BlueButtonWithGray
                        style={{ marginTop: '10px', marginLeft: '0px', width: '100%', fontSize: '.7rem' }}
                        className="join-button"
                        disabled={!memberToVote}
                        onClick={
                            memberToVote &&
                            (() => {
                                givePulse(memberToVote, algorithmId || '')
                            })
                        }
                        aria-label="Join now"
                    >
                        {<Text id="control.accept"></Text>}
                    </BlueButtonWithGray>

                    <TextButton
                        style={{
                            marginTop: '10px',
                            width: '100%',
                            fontSize: '.7rem',
                            paddingTop: 10,
                            paddingBottom: 10,
                            color: '#2B75FF',
                            fontWeight: 'bold',
                        }}
                        className="join-button"
                        onClick={() => {
                            givePulse('omit', algorithmId || '')
                        }}
                        aria-label="Cancel"
                    >
                        {<Text id="control.omit"></Text>}
                    </TextButton>
                </div>
            </Resume>
        </MainLayout>
    )
}

export default MandatoryVoteView

const Resume = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px 21px;
`

const BlueButtonWithGray = styled(WhiteButton)`
    background-color: #4c9aff;
    color: white;
    &:disabled {
        background-color: #eaeaea !important;
        color: #c7c7c7 !important;
    }
    &:hover:enabled {
        background-color: #105df9 !important;
        color: white !important;
    }
`

export const VoteList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 70px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`
