import styled, { css } from 'styled-components'
import { colors } from '../../style/variables'
import { PrimaryVoteButton } from './PrimaryVoteButton'
import { AttendanceStatus } from '../../interfaces/AttendanceStatus'

const handleAttendanceBg = (value: AttendanceStatus) => {
    switch (value) {
        case AttendanceStatus.UNSET:
            return 'white'
        case AttendanceStatus.PRESENT:
            return colors.eggColor
        case AttendanceStatus.DELAY:
            return '#66CCFF'
        case AttendanceStatus.ABSENT:
            return 'rgba(253,115,115,0.9);'
    }
}


export const MarkAttendanceButton = styled(PrimaryVoteButton)<{ attendance: 'unset' | 'delay' | 'present' | 'absent'}>`
    background-color: ${props => handleAttendanceBg(props.attendance) }
`