import styled from 'styled-components'
import { MainTitle } from './Type/Title'
import { colors } from '../style/variables'
import { IWidgetInfo } from '../interfaces/IWidgetInfo'
import { useState, useEffect, useContext } from 'preact/hooks'
import { Text } from 'preact-i18n'
import { LinkWithQuery } from './LinkWithQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIContext } from '../context/UIContext'
import AvatarEgg from './Avatar/AvatarEgg'
import { setGAWidgetNameProfileView } from '../helpers/GAnalytics'
import { ellipsis } from '../helpers/Tools'
import { IconBell } from './IconBell/IconBell'
import { useNavigation } from '../hooks/useNavigation'
import { AppContext } from '../app'

const MainHeading = ({ me, table, algorithm }: Partial<IWidgetInfo>) => {
    const { navigation } = useNavigation()
    const [completeName, setCompleteName] = useState(ellipsis(`${me?.name}`, 10))
    const { setShowZoomCode, hasNotifications, setShowStories} = useContext(UIContext)
    const { widgetInfo } = useContext(AppContext)

    useEffect(() => {
        if (completeName.length > 20) {
            setCompleteName(completeName.slice(0, 20) + '...')
        }
    }, [me])

    return (
        <Heading allReadStories={widgetInfo?.me.allReadStories} hasStory={widgetInfo?.me.hasStory}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                {widgetInfo?.team.settings.enabledZoomApp && (
                    <Linkzapp onClick={() => setShowZoomCode(true)}>
                        <FontAwesomeIcon
                            className="icon-zapp"
                            style={{ width: 22, height: 18 }}
                            icon={['fal', 'link-simple']}
                        />
                    </Linkzapp>
                )} 

                <IconBell
                    onClick={() => navigation('/notifications')}
                    hasNotifications={hasNotifications}
                />
            </div>
            <div className="data-container">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} >
                    <div className="img-container" onClick={() => widgetInfo?.me.allReadStories === null && widgetInfo?.me.hasStory === false ? null : setShowStories(true)}>
                        
                        <AvatarEgg
                            style={{border: '3px solid #FFFFFF', borderRadius: '50%', cursor: 'pointer'}}
                            title={`${me?.name} ${me?.lastname}`}
                            src={me?.urlImage ?? ''}
                            size={widgetInfo?.me.allReadStories === false && widgetInfo?.me.hasStory === false ? 62 : 58 }
                        />
                        
                    </div>
                    <div className="middle">
                        <MainTitle style={{ margin: '0', padding: '0' }}>
                            <div
                                className="title-heading"
                                onClick={() => setGAWidgetNameProfileView()}
                            >
                                <LinkWithQuery to={`/user/${me?._id}`}>
                                    <Text
                                        id="main_heading.greeting"
                                        fields={{ name: completeName }}
                                    >
                                        Name
                                    </Text>
                                </LinkWithQuery>
                            </div>
                            <Badge>
                                <Text
                                    id="list_table.team_number"
                                    fields={{ tableNumber: table?.tableNumber }}
                                ></Text>
                            </Badge>
                        </MainTitle>
                    </div>
                </div>
            </div>
        </Heading>
    )
}

export default MainHeading

const Heading = styled.div`
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 2px solid ${colors.borders}; */
    padding: 12px 0 14px;
    /* height:85px; */
    .data-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-transform: capitalize;
        line-height: 1.6rem;
        
        .img-container {
            /* max-width: 62px; */
            margin-right: 15px;
            img {
                width: 100%;
            }
            width: 71px;
            height: 71px;
            display: flex;
            justify-content: center;
            align-items: center;
            /* margin: 5px; */
            /* background:  */
            background: ${ (props) => props.allReadStories === null && props.hasStory === false ? '#fff' : props.allReadStories === true && props.hasStory === true ? '#E8E8E8'  : 'transparent linear-gradient(180deg, #FF647C 0%, #00A3FF 100%) 0% 0% no-repeat padding-box;' };
            border-radius: 50%;
        }
    }
    .switcher-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top:30px;
    }
`
const Linkzapp = styled.div`
    cursor: pointer;
    justify-items: center;

    .icon-zapp:hover{
        color: #2B75FF !important;
    }

`

export const Workgroup = styled.div`
    .icon {
        margin-right: 5px;
    }
    font-size: 12px;
    color: ${colors.darkGreyEgg};
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #EDEDED;
`

export const Badge = styled.div`
    color: #00A3FF;
    font-size: 10px;
    background-color: #BCE6FD;
    width: fit-content;
    padding: 4px 10px 4px 10px;
    border-radius: 20px;
    text-transform: uppercase;
    margin-top: 2px;
    font-weight: bold;
`

