import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'preact/hooks'
import { NotificationType, payload, UIContext } from '../../context/UIContext'
import styled from 'styled-components'

interface Props {
    payload?: payload,
    type: NotificationType.SUCCESS | NotificationType.ERROR
}

export const BottomNotification = ({ payload, type }: Props) => {
    const { dispatchNotification } = useContext(UIContext)
    return (
        <Container className={type}>
            <ContainerCenter className='notification-container'>
                {
                    payload?.icon !== undefined ?  payload?.icon : <FontAwesomeIcon icon={['fal', type === 'success' ? 'check' : 'times-circle']}  className='icon-styled' />
                }
                <ContainerText>
                    <TitleStyle>{ payload?.title }</TitleStyle>
                    <DescriptionStyle>
                        { payload?.subtitle }
                    </DescriptionStyle>
                </ContainerText>
            </ContainerCenter>

            <CloseSection>
                <FontAwesomeIcon
                    icon={['fal', 'xmark']}
                    style={{ cursor: 'pointer', paddingLeft: 13,paddingRight:13 }}
                    onClick={() => dispatchNotification({ type: null }) }
                />
            </CloseSection>
        </Container>
    )
}

export const ContainerCenterNotification = styled.div`
    padding: 0 20px;
    position: fixed;
    bottom: 50px;
`

const Container = styled.div`
    width: 100%;
    min-height: 72px;
    height: auto;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.success {
        background: #15c1b0 0% 0% no-repeat padding-box;
    }

    &.error {
        background: #ff647c 0% 0% no-repeat padding-box;
    }

    .icon-styled {
        font-size: 30px;
        color: #fff;
        padding: 20px;
        padding-left: 13px;
        padding-right: 13px;
    }
`

const ContainerCenter = styled.div`
    display: flex;
    align-items: 'center';
    width: 100%;
    height: 100%;
    
`

const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px 0;
`

const CloseSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ffffff;
    height: 70%;
    color: #fff;
    font-size: 24px;
    /* margin-left: 1em; */
`

const TitleStyle = styled.h3`
    color: white;
    display: 'inline-grid';
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    margin: 0;
`


const DescriptionStyle = styled.p`
    color: white;
    display: 'inline-grid';
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding-right: 4px;
    margin: 0;

`

