import dayjs from 'dayjs'
import { IWidgetInfo, TablePos, UserRole } from '../interfaces/IWidgetInfo'
type MyPermissionName = PermissionName | 'clipboard-read' | 'clipboard-write'

export const requestPermission = async (permission: MyPermissionName) => {
    return await navigator.permissions.query({ name: permission as PermissionName })
}

export const ellipsis = (valueString: string, length: number) => {
    if (valueString.length <= length || valueString === null) {
        return valueString
    }

    return valueString.substring(0, length) + '...'
}

export const getElapsedTime = (initialValue: Date) => {
    return dayjs(initialValue).fromNow()
}

export const isWithinFiveMinutes = (date: string) => {
    const dateObj = dayjs(date)
    const now = dayjs()
    const fiveMinutes = 5 * 60 * 1000
    return dateObj.isBetween(now.subtract(fiveMinutes, 'milliseconds'), now.add(fiveMinutes, 'milliseconds'))
}

export const getUserLabel = (item: TablePos, table: IWidgetInfo['table']) => {
    if (table?.facilitator === item._profileId) {
        return 'roles.facilitator'
    }
    if (item.isMentor && !item.isExpertMentor) {
        return 'roles.mentor'
    }
    if (item.isExpertMentor) {
        return 'roles.expertMentor'
    }
    return ''
}

export const getItemRole = (item: TablePos, table: IWidgetInfo['table']) => {
    if (table?.isMentorTeam || table?.isExpertMentorTeam) {
        if (item.isMentor || item.isExpertMentor) {
            return UserRole.STUDENT
        }
        switch (item.hrLevel) {
            case 1:
                return UserRole.MENTOR1
            case 2:
                return UserRole.MENTOR2
            case 3:
                return UserRole.EXPERTMENTOR
        }
        return UserRole.STUDENT
    } else {
        if (table?.facilitator === item._profileId) {
            return UserRole.FACILITATOR
        }
        switch (item.hrLevel) {
            case 1:
                return UserRole.MENTOR1
            case 2:
                return UserRole.MENTOR2
            case 3:
                return UserRole.EXPERTMENTOR
        }
        return UserRole.STUDENT
    }
}