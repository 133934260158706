import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import { useVoting } from '../../hooks/useVoting'
import { IWidgetInfo, IWidgetTable } from '../../interfaces/IWidgetInfo'
import { StateUpdater, useContext, useEffect, useState } from 'preact/hooks'
import { HelpModalType } from '../../interfaces/IWidgetInfo'
import { colors } from '../../style/variables'
import ToolTip from '../../components/Tooltip'
import { useText } from 'preact-i18n'
import BoltColor from '../../assets/boltColor.svg'
import { HCAType } from '../../interfaces/IWidgetInfo'
import { useNavigation } from '../../hooks/useNavigation'
import { IconPulse } from '../icons/IconPulse'
import { IconHROn } from '../icons/IconHROn'
import { IconHROff } from '../icons/IconHROff'
import { UIContext } from '../../context/UIContext'

interface IProps {
    widgetInfo?: IWidgetInfo
    helpRequest: IWidgetTable['helpRequest']
}

const ActionsBlock = ({ widgetInfo, helpRequest }: IProps) => {
    const { navigation } = useNavigation()
    const { voteTable } = useVoting()
    const { setShowActivateTeam, disabledActivateTeam, setDisabledActivateTeam, setRequestModal } = useContext(UIContext)
    const [showTooltip, setShowTooltip] = useState<'table-vote' | 'help-request' | 'join-meeting' | 'active-team' | null>(null)
    const [hideHelpRequestFeature, setHideHelpRequestFeature] = useState(false)
    const [hideActiveTeam, setHideActiveTeam] = useState(false)

    const { likeTableLabel, helpRequestLabel, onlineMeetingLabel, activateTeam } = useText({
        likeTableLabel: 'actions.labels.likeTeamLabel',
        helpRequestLabel: 'actions.labels.helpRequestLabel',
        onlineMeetingLabel: 'actions.labels.onlineMeetingLabel',
        activateTeam: 'actions.labels.activateTeam',
    })

    useEffect(() => {
        if (disabledActivateTeam) {
            setTimeout(() => {
                setDisabledActivateTeam(false)
            }, 120000)
        }
    }, [disabledActivateTeam])

    useEffect(() => {
        setHideHelpRequestFeature(
            process.env.PREACT_APP_ENABLED_HELP_REQUEST !== 'true' ||
                widgetInfo?.team.settings.hideAttendanceStats === true ||
                widgetInfo?.team.settings.algorithmType !== HCAType.HCA4 ||
                widgetInfo?.team.settings.enabledHelpRequest === false
        )
        setHideActiveTeam(!widgetInfo?.team.settings.enabledActivationTeam === true)
    }, [])

    const getMyPosition = () => {
        return widgetInfo?.table?.positions.findIndex(position => widgetInfo.me.profile._id === position._profileId)
    }

    const setProperRequestModal = () => {
        if (!helpRequest || helpRequest.status === 'ended') {
            setRequestModal('request')
        } else {
            if (helpRequest.status === 'in-progress' && widgetInfo?.algorithm?.isMentor && getMyPosition() === 0) {
                getMyPosition()
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'in-progress') {
                setRequestModal('in-progress')
                return
            }
            if (helpRequest.status === 'canceled') {
                setRequestModal('request')
                return
            }
            if (helpRequest.from._profileId === widgetInfo?.me.profile._id) {
                setRequestModal('requested-by-me')
            } else {
                setRequestModal('requested')
            }
        }
    }

    const joinMeeting = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const source = queryParams.get('sourceDomain')
        if (window && window.top && source) {
            window.top.postMessage(
                {
                    eventName: 'join-meeting-clicked',
                    meetingUrl: widgetInfo?.meeting,
                },
                source
            )
        }
        widgetInfo?.meeting ? navigation('/meeting') : null
    }

    return (
        <ActionsContainer>
            <BtnContainer onMouseOver={() => setShowTooltip('table-vote')} onMouseLeave={() => setShowTooltip(null)}>
                <BtnIcon
                    onClick={() => voteTable()}
                    voted={widgetInfo?.table?.voteData?.hasVoted}
                    // disabled={widgetInfo?.algorithm?.isMentor || !widgetInfo?.table}
                    disabled={!widgetInfo?.table}
                    aria-label="I like this team"
                >
                    <img className="icon-pulse-img" style={{ width: '12px' }} src={BoltColor} alt="" />
                    <IconPulse className="icon-pulse-svg" style={{ width: '18px' }} />
                </BtnIcon>
                {showTooltip === 'table-vote' && <ToolTip content={likeTableLabel} direction="top" delay={100} />}
            </BtnContainer>
            {hideHelpRequestFeature === false && (
                <BtnContainer onMouseOver={() => setShowTooltip('help-request')} onMouseLeave={() => setShowTooltip(null)}>
                    <BtnIcon
                        status={helpRequest?.status}
                        disabled={!widgetInfo?.table}
                        onClick={() => setProperRequestModal()}
                        aria-label="Request help"
                    >
                        {helpRequest?.status === 'requested' ? <IconHROn className="icon" /> : <IconHROff className="icon" />}
                    </BtnIcon>
                    {showTooltip === 'help-request' && <ToolTip content={helpRequestLabel} direction="top" delay={100} />}
                </BtnContainer>
            )}
            {hideActiveTeam === false && (
                <BtnContainer onMouseOver={() => setShowTooltip('active-team')} onMouseLeave={() => setShowTooltip(null)}>
                    <BtnIcon disabled={!widgetInfo?.table || disabledActivateTeam} onClick={() => setShowActivateTeam(true)} aria-label="Active Team">
                        <FontAwesomeIcon className="icon" icon={['fas', 'wave-pulse']} />
                    </BtnIcon>
                    {showTooltip === 'active-team' && <ToolTip content={activateTeam} direction="top" delay={100} />}
                </BtnContainer>
            )}

            {!hideActiveTeam && !hideHelpRequestFeature && <Border />}

            <BtnContainer
                style={{ flex: !hideActiveTeam && !hideHelpRequestFeature ? 'none' : '' }}
                onMouseOver={() => setShowTooltip('join-meeting')}
                onMouseLeave={() => setShowTooltip(null)}
            >
                <BtnIcon
                    style={{ paddingLeft: 8, paddingRight: 8, width: !hideActiveTeam && !hideHelpRequestFeature ? '40px' : '100%' }}
                    disabled={!widgetInfo?.meeting}
                    onClick={() => joinMeeting()}
                    aria-label="Join meeting"
                >
                    <FontAwesomeIcon className="icon" icon={['fas', 'video']} />
                </BtnIcon>
                {showTooltip === 'join-meeting' && (
                    <ToolTip
                        showContentDirection={hideActiveTeam === false ? 'left' : 'center'}
                        content={onlineMeetingLabel}
                        direction="top"
                        delay={100}
                    />
                )}
            </BtnContainer>
        </ActionsContainer>
    )
}

export default ActionsBlock

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    /* margin-top: 15px; */
    margin-bottom: 10px;
    button {
        width: 100%;
    }
`

const Border = styled.div`
    height: 40px;
    border-left: 1px solid #EDEDED;

`


const BtnContainer = styled.div`
    position: relative;
    flex:1;
    /* &:first-child {
        margin-right: 5px;
    }
    &:last-child {
        margin-left: 5px;
    } */
`

const BtnIcon = styled(WhiteButton)`
    background-color: ${({ status }) => (status === 'requested' ? '#00A3FF' : 'none')};
    height: 40px;
    box-shadow: 0px 0px 20px #00375014;
    border: 1px solid ${colors.borders};
    border-radius: 12px;
    .icon {
        margin-right: 0;
        font-size: 18px;
        color: ${colors.darkGreyEgg};
    }
    .icon-pulse-svg {
        display: block;
    }
    .icon-pulse-img {
        display: none;
    }
    &:not(:disabled):hover {
        /* background-color: #4c9aff !important; */
        .icon {
            color: #4c9aff;
        }
        .icon-pulse-img {
            display: block;
        }

        .icon-pulse-svg {
            display: none;
        }
    }
    ${({ voted }) =>
        voted &&
        css`
            background: ${colors.darkGreyEgg};
            color: white;
            .icon {
                color: white;
            }

            .icon-pulse-svg {
                display: none;
            }

            .icon-pulse-img {
                display: block;
            }
        `}

    .icon-active {
        color: white;
    }
`
