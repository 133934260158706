
interface Props {
    rest?: [x: string],
    className?: string,
    style?: Record<string, any>
}


export const IconPulse = ({ className, style, ...rest }:Props) => {
  return (
    <svg className={ className } style={style} { ...rest } version="1.1" id="Capa_1"  x="0px" y="0px" viewBox="0 0 512 512">
        <path d="M228.94,304.06H105.58c-9.06,0-14.32-10.25-9.05-17.61L298.43,4.62c6.73-9.38,21.53-3.59,20.09,7.86l-24.57,196.55 l111.64-8.27c9.36-0.69,15.32,9.8,9.94,17.49L213.23,507.27c-6.86,9.8-22.23,3.36-20.07-8.4L228.94,304.06z" fill="currentColor"/>
    </svg>

  )
}
