import { useContext, useEffect, useLayoutEffect } from 'preact/hooks';
import styled from 'styled-components';
import { AppContext } from '../../app'
import loader from '../../assets/loader.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text } from 'preact-i18n';
import { UIContext } from '../../context/UIContext';
import clockSvg from '../../assets/clock.svg'
import { WhiteButton } from '../Buttons/WhiteButton';
import { useAttendance } from '../../hooks/useAttendance';
import { setGAWidgetRotationLocked } from '../../helpers/GAnalytics'

const WaitingArea = () => {
    
    const { widgetInfo } = useContext(AppContext);
    const { canSetPresent, timeCurrent, setTimeCurrent } = useContext(UIContext)
    const { markAttendance } = useAttendance()
    
    let timeOut:any;

    useLayoutEffect(() => { 
        timeOut = setTimeout(() => setTimeCurrent(timeCurrent - 1), 1000);
        timeCurrent > 0 && timeOut
        return () => {
            clearTimeout(timeOut)
        }
    }, [timeCurrent])

    const handleClickPresent =  async () => {
      const result = await  markAttendance()
      if(!result){
        setTimeCurrent(120)
      }
    }

    useEffect(()=> {
        if(canSetPresent === false  && canSetPresent !== undefined ){
            setGAWidgetRotationLocked()
        }
    }, [])

    const getTextContent = () => {

        if(canSetPresent === false  && canSetPresent !== undefined ){
            return (
                <div style={{ width: '100%', height: '100%' }}>
                    <img style={{ width: 89, height: 79 }} src={clockSvg} alt="" />
                    <p style={{ fontSize: 14, fontWeight: 400, lineHeight: '20px'}}>
                        <Text id={"waiting-area.timer.title"}>
                            Aguarde unos instantes, estamos creando los equipos para el encuentro de hoy.
                        </Text>
                    </p>
                    <p style={{ fontSize: 14, fontWeight: 'bold', lineHeight: '22px'}}>
                        <Text id={"waiting-area.timer.time-left"}>
                            Tiempo restante :
                        </Text>
                    </p>
                    <span style={{ fontSize: 14, fontWeight: 400, lineHeight: '20px'}}>
                        {
                            timeCurrent > 0
                                ? timeCurrent + ' seg.'
                                : ' '
                        }
                    </span>
                    <WhiteButton
                        disabled={ timeCurrent > 0 }
                        className="btn-yellow"
                        style={{ margin: '0 auto', width: '100%' }}
                        onClick={handleClickPresent}
                    >
                        <Text id="waiting-area.timer.button">Ingresar</Text>
                    </WhiteButton>
                </div>
            );
        }

        switch(widgetInfo?.meeting?.type){
            case 'zoom':
                return(
                    <div>
                        <span>
                            <strong>
                                <Text id={"waiting-area.onboarding.main"}>
                                    Por favor, espera unos minutos para que los equipos sean conformados
                                </Text>
                            </strong>
                        </span>
                        <span><Text id={"waiting-area.onboarding.secondary"}>Mientras tanto puedes acceder a la videollamada desde el botón “Encuentro virtual” </Text><FontAwesomeIcon style={{marginLeft:'10px'}} icon={['fas','video']} /></span>
                        <span><img src={loader} height="50" alt="Loading gif" /></span>
                    </div>
                )
            case 'zoom-by-table':
                return(
                    <div>
                        <span>
                            <Text id={"waiting-area.default.main"}>Por favor espera unos minutos. Una vez que los equipos sean conformados podrás ingresar al encuentro virtual.</Text>
                        </span>
                        <span><img src={loader} height="50" alt="Loading gif" /></span>
                    </div>
                )
            default: return(
                <div>
                    <span>
                        <Text id={"waiting-area.default.main"}>Por favor espera unos minutos. Una vez que los equipos sean conformados podrás ingresar al encuentro virtual.</Text>
                    </span>
                    <span><img src={loader} height="50" alt="Loading gif" /></span>
                </div>
            )
        }
    }
    
    return (
        <WaitingComponent>
            {getTextContent()}
        </WaitingComponent>
        )
    }
    
    export default WaitingArea
    
    
    const WaitingComponent = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    text-align:center;
    padding:40px;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    span{
        font-size:14px;
        margin:8px 0;
        line-height:1.4rem;
        display:block;
    }
    `